import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { ThemeContext } from 'styled-components';
import { toast } from 'react-toastify';
import { differenceInCalendarMonths, format } from 'date-fns';
import { Container } from './styles';
import api from 'services/api';
import dateUTC from 'utils/dateUTC';
import Modal from 'components/Modal';
import FinancialIndependece from './templates/financialIndependece';
import Default from './templates/default';

export default function UpdateGoal({
  fetchBalance,
  selectedGoal,
  setGoals,
  setShowUpdateModal,
  setShowModal,
  goals,
  financialBalance,
  patrimonialBalance,
  showModal,
}) {
  const { id: userId } = useSelector(state => state.user.profile);
  // Dados do formulário
  const [id] = useState(selectedGoal.id);
  const [name, setName] = useState(selectedGoal.name);
  const [description, setDescription] = useState(selectedGoal.description);
  const [value, setValue] = useState(selectedGoal.value);
  const [savedMoney, setSavedMoney] = useState(selectedGoal.saved_money);
  const [profitability, setProfitability] = useState(
    selectedGoal.profitability
  );
  const [end, setEnd] = useState(
    format(new Date(selectedGoal.end), 'yyyy-MM-dd')
  );

  const [thumbnail, setThumbnail] = useState(
    selectedGoal.file ? selectedGoal.file.url : null
  );
  const [toggleSwitch, setToggleSwitch] = useState(!selectedGoal.value);
  const [liquidity, setLiquidity] = useState(selectedGoal.liquidity);
  const [liquidityBpl, setLiquidityBpl] = useState(selectedGoal.liquidity_bpl);
  const [loading, setLoading] = useState(false);
  const [bpl, setBpl] = useState(selectedGoal.bpl);
  const [plf, setPlf] = useState(selectedGoal.plf);
  const [allocationStrategy, setallocationStrategy] = useState(
    selectedGoal.strategic_allocation
  );
  const [patrimonialActiveValue, setPatrimonialActiveValue] = useState(
    selectedGoal.patrimonial_active_value
  );
  const [financialActiveValue, setFinancialActiveValue] = useState(
    selectedGoal.financial_active_value
  );
  const [financialActiveValueBpl, setFinancialActiveValueBpl] = useState(
    selectedGoal.financial_active_value_bpl
  );
  const [allocationStrategyBpl, setallocationStrategyBpl] = useState(
    selectedGoal.strategic_allocation_bpl
  );
  // importar acceptedFiles
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const theme = useContext(ThemeContext);

  // const files = acceptedFiles.map(file => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  useEffect(() => {
    if (acceptedFiles[0]) {
      setThumbnail(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  /**
   * Calcula o investimento mensal
   */
  useEffect(() => {
    setSavedMoney(financialActiveValue + patrimonialActiveValue);
  }, [patrimonialActiveValue, financialActiveValue]);

  function calcIFP() {
    // prazo é sub de end até new Date;
    // const pv é o que já possui
    // const i é taxa
    // const FV valor do sonho
    const isFirstDayOfMonth = end.split('-')[2] === '01';
    const subtractValue = isFirstDayOfMonth ? 0 : 1;
    const differenceInMonths =
      differenceInCalendarMonths(new Date(end), new Date()) - subtractValue + 1;
    const n = differenceInMonths <= 0 ? 1 : differenceInMonths;
    const i = profitability / 100;
    const fv = value - patrimonialActiveValue;
    const pv = financialActiveValue;
    const ifp = (fv - pv * (1 + i) ** n) * (((1 + i) ** n - 1) / i) ** -1;

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(ifp)) {
      return 0;
    }

    return ifp.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }
  async function createGoal() {
    if (!name || !description || !end) {
      setLoading(false);
      toast.warn(
        'É Obrigatório preenchear o nome, o que significa pra você e a data'
      );
      return;
    }
    const endDate = new Date(end);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (endDate <= today) {
      setLoading(false);
      return toast.warn('A data de conclusão deve ser maior que a data atual');
    }
    if (!description || description.trim().length <= 2) {
      setLoading(false);
      return toast.warn('Descreva melhor o significado do sonho para você.');
    }
    setLoading(true);

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    const file = await api.post('/files', formData);

    try {
      const { data: goal } = await api.post(`/users/${userId}/goals`, {
        name,
        description,
        value,
        profitability,
        end: dateUTC(end),
        file_id: file.data.id,
        saved_money: savedMoney,
        strategic_allocation: allocationStrategy,
        strategic_allocation_bpl: allocationStrategyBpl,
        patrimonial_active_value: patrimonialActiveValue,
        financial_active_value: financialActiveValue,
        bpl,
        plf,
        financial_active_value_bpl: financialActiveValueBpl,
        type: 'financial-independence',
      });
      const newGoals = goals.filter(
        goal => goal.type !== 'financial-independence'
      );
      setGoals([goal, ...newGoals]);
      setName('');
      setDescription('');
      setValue(0);
      setProfitability(0);
      setEnd(format(new Date(), 'yyyy-MM-dd'));
      setSavedMoney(0);
      acceptedFiles.length = 0;
      setPatrimonialActiveValue(0);
      setFinancialActiveValue(0);
      setallocationStrategy('');
      setLoading(false);
      setShowUpdateModal(false);
      setShowModal(false);
      toast.success('Sonho criado com sucesso!');
    } catch (error) {}
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const endDate = new Date(end);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (endDate <= today) {
        setLoading(false);
        return toast.warn('A data de conclusão deve ser maior que a data atual');
      }
      if (!description || description.trim().length <= 2) {
        setLoading(false);
        return toast.warn('Descreva melhor o significado do sonho para você.');
      }

      if (selectedGoal.id === -1) {
        return createGoal();
      }
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);

      const file = await api.post('/files', formData);
      let goalToUpdate;

      if (selectedGoal.type === 'financial-independence') {
        goalToUpdate = {
          name,
          description,
          value,
          profitability,
          file_id: file.data.id,
          end: dateUTC(end),
          saved_money: savedMoney,
          strategic_allocation: allocationStrategy,
          strategic_allocation_bpl: allocationStrategyBpl,
          patrimonial_active_value: patrimonialActiveValue,
          financial_active_value: financialActiveValue,
          bpl,
          plf,
          financial_active_value_bpl: financialActiveValueBpl,
          liquidity,
          liquidity_bpl: liquidityBpl,
        };
      } else {
        if (!toggleSwitch) {
          goalToUpdate = {
            name,
            description,
            value,
            profitability,
            file_id: file.data.id,
            end: dateUTC(end),
            saved_money: savedMoney,
            strategic_allocation: allocationStrategy,
            patrimonial_active_value: patrimonialActiveValue,
            financial_active_value: financialActiveValue,
            liquidity,
          };
        } else {
          goalToUpdate = {
            name,
            description,
            value: 0,
            profitability: 0,
            file_id: file.data.id,
            end: dateUTC(end),
            saved_money: 0,
            strategic_allocation: '',
            patrimonial_active_value: 0,
            financial_active_value: 0,
            liquidity: '',
          };
        }
      }
      const { data } = await api.put(`/goals/${id}`, goalToUpdate);
      await fetchBalance();

      setGoals(goals =>
        goals.map(goal => {
          if (goal.id !== id) return goal;
          return data;
        })
      );

      setShowUpdateModal(false);
      acceptedFiles.length = 0;
      setLoading(false);
      return toast.success('Editado com sucesso');
    } catch (err) {
      setShowUpdateModal(false);
      setLoading(false);
      return toast.error('Falha ao editar');
    }
  }
  // valor total sonho
  // useEffect(() => {
  //   const result = (bpl + plf)
  //   setValue(result);
  // }, [bpl, plf])
  // // bpl e plf atual (selado)
  // useEffect(() => {
  //   const result = (patrimonialActiveValue + financialActiveValue + financialActiveValueBpl)
  //   setSavedMoney(result);
  // }, [patrimonialActiveValue, financialActiveValue, financialActiveValueBpl])

  return (
    <Container>
      <Modal
        color="info"
        setShowModal={setShowUpdateModal}
        title="Editar sonho"
        icon={null}
        width="800px"
      >
        {selectedGoal.type === 'financial-independence' ? (
          <FinancialIndependece
            name={name}
            theme={theme}
            selectedGoal={selectedGoal}
            acceptedFiles={acceptedFiles}
            calcIFP={calcIFP}
            handleSubmit={handleSubmit}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            setName={setName}
            description={description}
            setDescription={setDescription}
            value={value}
            setValue={setValue}
            savedMoney={savedMoney}
            setSavedMoney={setSavedMoney}
            profitability={profitability}
            setProfitability={setProfitability}
            setShowUpdateModal
            end={end}
            setEnd={setEnd}
            thumbnail={thumbnail}
            setThumbnail={setThumbnail}
            loading={loading}
            setLoading={setLoading}
            bpl={bpl}
            setBpl={setBpl}
            plf={plf}
            setPlf={setPlf}
            allocationStrategy={allocationStrategy}
            setallocationStrategy={setallocationStrategy}
            patrimonialActiveValue={patrimonialActiveValue}
            setPatrimonialActiveValue={setPatrimonialActiveValue}
            financialActiveValue={financialActiveValue}
            setFinancialActiveValue={setFinancialActiveValue}
            financialActiveValueBpl={financialActiveValueBpl}
            setFinancialActiveValueBpl={setFinancialActiveValueBpl}
            allocationStrategyBpl={allocationStrategyBpl}
            setallocationStrategyBpl={setallocationStrategyBpl}
            liquidity={liquidity}
            setLiquidity={setLiquidity}
            liquidityBpl={liquidityBpl}
            setLiquidityBpl={setLiquidityBpl}
            financialBalance={financialBalance}
            patrimonialBalance={patrimonialBalance}
          />
        ) : (
          <Default
            name={name}
            theme={theme}
            selectedGoal={selectedGoal}
            acceptedFiles={acceptedFiles}
            calcIFP={calcIFP}
            handleSubmit={handleSubmit}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            setName={setName}
            description={description}
            setDescription={setDescription}
            value={value}
            setValue={setValue}
            savedMoney={savedMoney}
            setSavedMoney={setSavedMoney}
            profitability={profitability}
            setProfitability={setProfitability}
            setShowUpdateModal
            end={end}
            setEnd={setEnd}
            thumbnail={thumbnail}
            setThumbnail={setThumbnail}
            loading={loading}
            setLoading={setLoading}
            bpl={bpl}
            setBpl={setBpl}
            plf={plf}
            setPlf={setPlf}
            allocationStrategy={allocationStrategy}
            setallocationStrategy={setallocationStrategy}
            patrimonialActiveValue={patrimonialActiveValue}
            setPatrimonialActiveValue={setPatrimonialActiveValue}
            financialActiveValue={financialActiveValue}
            setFinancialActiveValue={setFinancialActiveValue}
            financialActiveValueBpl={financialActiveValueBpl}
            setFinancialActiveValueBpl={setFinancialActiveValueBpl}
            allocationStrategyBpl={allocationStrategyBpl}
            setallocationStrategyBpl={setallocationStrategyBpl}
            toggleSwitch={toggleSwitch}
            setToggleSwitch={setToggleSwitch}
            liquidity={liquidity}
            setLiquidity={setLiquidity}
            liquidityBpl={liquidityBpl}
            setLiquidityBpl={setLiquidityBpl}
            financialBalance={financialBalance}
            patrimonialBalance={patrimonialBalance}
          />
        )}
      </Modal>
    </Container>
  );
}

UpdateGoal.propTypes = {
  selectedGoal: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.number,
    profitability: PropTypes.number,
    end: PropTypes.string,
    file_id: PropTypes.number,
    saved_money: PropTypes.number,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  setGoals: PropTypes.func.isRequired,
  setShowUpdateModal: PropTypes.func.isRequired,
};
