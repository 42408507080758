import React, { useEffect } from 'react'
import Input from 'components/Input';
import Button from 'components/Button';
import { Form, DivDropzone } from '../../../../styles';
import { FaSpinner } from 'react-icons/fa';
import { MdPhotoCamera } from 'react-icons/md';
import { differenceInCalendarMonths } from 'date-fns';
import { CurrencyInput } from 'components/masks';
import { goalsAllocationDefault } from 'utils/defaultsDropDown';
import Dropdown from 'components/Input/Dropdown';
import ReactTooltip from 'react-tooltip';
export default function FinancialIndependece(
  {
    name,
    acceptedFiles,
    getRootProps,
    theme,
    handleSubmit,
    selectedGoal,
    // calcIFP,
    getInputProps,
    setName,
    description,
    setDescription,
    value,
    setValue,
    savedMoney,
    setSavedMoney,
    profitability,
    setProfitability,
    end,
    setEnd,
    thumbnail,
    setThumbnail,
    toggleSwitch,
    setToggleSwitch,
    loading,
    setLoading,
    bpl,
    setBpl,
    plf,
    setPlf,
    allocationStrategy,
    setallocationStrategy,
    patrimonialActiveValue,
    setPatrimonialActiveValue,
    financialActiveValue,
    setFinancialActiveValue,
    financialActiveValueBpl,
    setFinancialActiveValueBpl,
    allocationStrategyBpl,
    setallocationStrategyBpl,
    patrimonialBalance,
    financialBalance

  }
) {

  function calcIFP() {


    // prazo é sub de end até new Date;
    // const pv é o que já possui
    // const i é taxa
    // const FV valor do sonho
    // setSavedMoney(financialActiveValue + patrimonialActiveValue)
    setSavedMoney(financialActiveValueBpl + patrimonialActiveValue + financialActiveValue);
    const i = profitability / 100;
    const fv = value - patrimonialActiveValue;
    const pv = financialActiveValue + financialActiveValueBpl;
    const isFirstDayOfMonth = end.split('-')[2] === '01';
    const subtractValue = isFirstDayOfMonth ? 0 : 1;
    const differenceInMonths= (differenceInCalendarMonths(new Date(end), new Date()) - subtractValue) + 1;
    const n = differenceInMonths <= 0 ? 1 : differenceInMonths;
    const ifp = (fv - pv * (1 + i) ** n) * (((1 + i) ** n - 1) / i) ** -1;

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(ifp)) {
      return 0;
    }

    return ifp.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  useEffect(() => {
    setPlf(value - bpl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, bpl])

  return (
    <Form column>
      <div className='left'>
        <Input
          label="Novo Sonho:"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Input
          label="O que significa pra você?"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <DivDropzone thumbnail={thumbnail}>
          <div {...getRootProps({ className: 'dropzone' })}>
            {acceptedFiles.length > 0 ? (
              <h3>{acceptedFiles[0].name}</h3>
            ) : (
              <MdPhotoCamera />
            )}
            <input {...getInputProps()} />
          </div>
        </DivDropzone>

        <div className="inputGroup">
          <Input
            label="Prazo:"
            value={end}
            type="date"
            onChange={e => setEnd(e.target.value)}
          />

        </div>
        <CurrencyInput
          label="Valor total independência financeira:"
          onChange={setValue}
          value={value}
        />

        {toggleSwitch && (

          <div className="buttonGroup">
            <Button onClick={e => handleSubmit(e)}>
              {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
            </Button>
          </div>
        )}
        <div className="inputGroup">
          <CurrencyInput
            label="BPL - Base de Plena Liquidez total:"
            value={bpl}
            onChange={setBpl}
          />
          <CurrencyInput
            label="BPL - Base de Plena Liquidez atual:"
            data-tip={`Disponível: ${(financialBalance)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}

            value={financialActiveValueBpl}
            onChange={setFinancialActiveValueBpl}
          />

        </div>
      </div>
      {/* feito */}
      <div className='right'>

        <Input
          label="Valor total do portifólio de liberdade financeira:"
          disabled
          prefix="R$ "
          value={plf?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}

        />


        <div className="inputGroup">

          <CurrencyInput
            label="PLF - Patrimônio Imobilizado atual:"
            data-tip={`Portifólio de Liberdade Financeira - Disponível: ${patrimonialBalance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}
            value={patrimonialActiveValue}
            onChange={setPatrimonialActiveValue}
          />
          <CurrencyInput
            label="PLF - Patrimônio Financeiro atual:"
            data-tip={`Portifólio de Liberdade Financeira - Disponível ${financialBalance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}
            value={financialActiveValue}
            onChange={setFinancialActiveValue}
          />
        </div>
        <div className="inputGroup">
          <Input
            style={{ background: '#e9e9e9' }}
            label="PLF - Valor total atual:"
            prefix="R$ "
            disabled
            value={(patrimonialActiveValue + financialActiveValue)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })} />
          <CurrencyInput
            suffix="% a.m."
            label="Rentabilidade:"
            value={profitability}
            onChange={e => setProfitability(e)}
          />
        </div>
        <div className="inputGroup drop">
          <Dropdown
            label="BPL - Estratégia de alocação:"
            options={goalsAllocationDefault}
            defaultValue={{
              value: allocationStrategyBpl,
              label: allocationStrategyBpl,
            }}
            onChange={selected => setallocationStrategyBpl(selected.value)}
          />
          <Dropdown
            label="PLF - Estratégia de alocação:"
            options={goalsAllocationDefault}
            defaultValue={{
              value: allocationStrategy,
              label: allocationStrategy,
            }}
            onChange={selected => setallocationStrategy(selected.value)}
          />
        </div>
        <div className="inputGroup">
          <Input
            label="Valor atual do sonho:"
            disabled={selectedGoal.type === 'financial-independence' ? true : false}
            prefix="R$ "
            value={(savedMoney || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          />
          <Input
            style={{ background: '#e9e9e9' }}
            label="Investimento Mensal:"
            disabled value={calcIFP()} />
        </div>

        <div className="buttonGroup">
          <Button onClick={e => handleSubmit(e)}>
            {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
          </Button>
        </div>
      </div>
      <ReactTooltip />
    </Form>
  )
}
